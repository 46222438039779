/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;

  &:before {
    // content: "";
    // background: $theme-color;
    // z-index: 1;
    // opacity: 0.1;
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
  }

  a,
  p > a {
    color: $theme-heading-color;
    font-weight: 600;
  }

  .btn-auth-gen {
    .btn-icon {
      width: 140px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 45px;

      small {
        font-size: 15px;
      }
    }
  }

  .card {
    margin-bottom: 0;
  }

  > div {
    z-index: 5;
  }

  .auth-content {
    position: relative;
    width: 390px;
    padding: 15px;

    &.multyform,
    &.subscribe {
      width: 750px;
    }
  }
  @media only screen and (max-width: 768px) {
    max-width: 360px;
  }
  @media only screen and (max-width: 575px) {
    .card {
      .card-body {
        padding: 30px 15px;
      }
    }
  }

  .auth-icon {
    font-size: 30px;

    &:before {
      background: #dfcc20;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.offline {
    background-image: none;

    &:before {
      display: none;
    }
  }

  .auth-bg {
    .r {
      position: absolute;
      width: 300px;
      height: 300px;
      border-radius: 50%;

      &:first-child {
        top: -100px;
        right: -100px;
        background: #dfcc20;
      }

      &:last-child {
        left: -100px;
        bottom: -100px;
        background: #0a66c2;
      }

      &.s {
        width: 20px;
        height: 20px;
        &:nth-child(2) {
          top: 150px;
          right: -150px;
          background: $primary-color;
        }

        &:nth-child(3) {
          left: -150px;
          bottom: 150px;
          background: #dfcc20;
        }
      }

      &:nth-child(odd) {
        animation: floating 7s infinite;
      }

      &:nth-child(even) {
        animation: floating 9s infinite;
      }
    }
  }
}
.auth-wrapper.offline {
  background: radial-gradient(#94acbe, #253653);
}
@keyframes floating {
  from {
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}

@keyframes boatanim {
  0% {
    transform: rotate(-10deg) translate(95px, -14px);
  }
  50% {
    transform: rotate(5deg) translate(-65px, -14px);
  }
  100% {
    transform: rotate(-10deg) translate(95px, -14px);
  }
}
@-webkit-keyframes sunwawe {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}
@keyframes sunwawe {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}
@-webkit-keyframes sparcle {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
}

@keyframes sparcle {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
}
.btn-theme,
a.btn-theme {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border-color: #1de1c2;
  border-radius: 0.25rem;
  padding: 11px 25px;
}
.btn-theme:active,
.btn-theme:focus,
.btn-theme:not(:disabled):not(.disabled):active,
a.btn-theme:active,
a.btn-theme:focus,
a.btn-theme:not(:disabled):not(.disabled):active {
  background-image: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  color: #fff;
  box-shadow: none;
}
.btn-theme.active,
a.btn-theme.active {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none;
}
.btn-outline-theme {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none;
}
.btn-outline-theme:active,
.btn-outline-theme:focus,
.btn-outline-theme:not(:disabled):not(.disabled):active {
  background-image: #fff;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
}
.btn-outline-theme.active {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%) !important;
  color: #fff;
}

@keyframes sark {
  0% {
    transform: rotate(-10deg) translate(245px, 46px);
  }
  30% {
    transform: rotate(-13deg) translate(-329px, -80px);
  }
  60% {
    transform: rotate(-13deg) translate(-329px, -80px);
  }
  100% {
    transform: rotate(-26deg) translate(-887px, -80px);
  }
}
@-webkit-keyframes boatanim {
  0% {
    transform: rotate(-10deg) translate(95px, -14px);
  }
  50% {
    transform: rotate(5deg) translate(-65px, -14px);
  }
  100% {
    transform: rotate(-10deg) translate(95px, -14px);
  }
}
/**====== Authentication css end ======**/
