/* You can add global styles to this file, and also import other style files */

@import 'scss/variables';

/* fonts-icon */
@import 'scss/fonts/feather/iconfont';

@import 'scss/general';
@import 'scss/mixins/function';

@import 'scss/menu/menu-lite';

@import 'scss/theme-elements/theme-elements';


@import '../node_modules/ngx-toastr/toastr';

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
