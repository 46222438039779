body {
  font-family: $theme-font-family;
  font-size: $theme-font-size;
  color: $theme-font-color;
  font-weight: 400;
  background: $theme-background;
  position: relative;
}


* {
  &:focus {
    outline: none;
  }
}

p {
  font-size: 14px;
}

strong {
  font-weight: 400;
}


.anim-rotate {
  animation: anim-rotate 1s linear infinite;
}
@keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}

p.text-muted {
  font-size: 13px;
}

.card {
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;

  .card-header {
    background-color: transparent;
    border-bottom: 1px solid #f1f1f1;
    // border-bottom: none;
    padding: 20px 25px;
    position: relative;

    h5 {
      margin-bottom: 0;
      color: #000;
      font-size: 17px;
      font-weight: 400;
      display: inline-block;
      margin-right: 10px;
      line-height: 1.1;
      position: relative;

      &:after {
        content: '';
        background-color: $primary-color;
        position: absolute;
        left: -25px;
        top: 0;
        width: 4px;
        height: 20px;
      }
    }

    &.borderless {
      border-bottom: none;

      h5:after {
        display: none;
      }
    }

    .card-header-right {
      right: 10px;
      top: 10px;
      display: inline-block;
      padding: 0;
      position: absolute;
      @media only screen and (max-width: 575px) {
        display: none;
      }

      .dropdown-menu {
        margin-top: 0;

        li {
          a {
            font-size: 14px;
          }
        }
      }

      .btn.dropdown-toggle {
        border: none;
        background: transparent;
        box-shadow: none;
        color: #888;

        i {
          margin-right: 0;
        }

        &:after {
          display: none;
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }

  .card-footer {
    border-top: 1px solid #f1f1f1;
    background: transparent;
    padding: 25px;
  }

  .card-block,
  .card-body {
    padding: 30px 25px;
  }

  &.card-load {
    position: relative;
    overflow: hidden;

    .card-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: rgba(256, 256, 256, 0.7);
      z-index: 999;

      i {
        margin: 0 auto;
        color: $primary-color;
        font-size: 24px;
        align-items: center;
        display: flex;
      }
    }
  }

  &.full-card {
    z-index: 99999;
    border-radius: 0;
  }
}

// ================================    Dropdown Start  =====================

.dropdown-menu {
  padding: 20px 0;
  margin-top: 15px;
  box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
  border: none;

  &.show {
    &:before {
      content: '\63';
      font-family: 'pct';
      position: absolute;
      left: 15px;
      top: -5px;
      z-index: 1001;
      font-size: 40px;
      line-height: 0;
      color: #fff;
      text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12);
    }

    &.dropdown-menu-end {
      &:before {
        left: auto;
        right: 5px;
      }
    }
  }

  > li {
    padding-left: 15px;
    padding-right: 15px;

    > a {
      padding: 5px;
      color: $theme-font-color;

      i {
        font-size: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
    }

    &.active,
    &:active,
    &:focus,
    &:hover {
      background: transparentize($primary-color, 0.9);

      > a {
        background: transparent;
      }
    }
  }
}
// ================================    Dropdown End  =====================
@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}

.ms-container {
  width: 100%;
}

.card .animated {
  animation-duration: 1s;
  border: 5px solid #fff;
  margin-bottom: 30px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}
