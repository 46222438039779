@font-face {
  font-family: 'feather';
  src: url('fonts/feather.eot?t=1501841394106');
  
  src:
    url('fonts/feather.eot?t=1501841394106#iefix') format('embedded-opentype'),
    url('fonts/feather.woff?t=1501841394106') format('woff'),
    url('fonts/feather.ttf?t=1501841394106') format('truetype'),
    url('fonts/feather.svg?t=1501841394106#feather') format('svg');
}
